@import 'styles/variables.scss';
.review-star {
  display: flex;
  align-items: center;
  .star {
    padding-bottom: 2px;
    margin-right: 10px;
  }
  .point {
    color: $col-accent;
    font-weight: bold;
  }
  &.is-white {
    .star {
      margin-right: 5px;
    }
    .point {
      color: #fff;
      font-weight: normal;
    }
  }
  &.is-black {
    .star {
      margin-right: 5px;
    }
    .point {
      color: $col-base;
    }
  }
  .un-rated {
    font-weight: normal;
    color: $col-gray6;
  }
}
