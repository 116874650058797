@import 'styles/variables.scss';
.purpose {
  font-size: 1.2rem;
  font-weight: bold;
  display: inline-block;
  padding: 8px 10px;
  margin: 0 5px 5px 0;
  border: 1px $col-gray2 solid;
  border-radius: 3px;
  color: $col-base;
}
