@import 'styles/variables.scss';
.area-list {
  &.is-lock {
    pointer-events: none;
  }
}

.location-link {
  color: $col-base;
  display: block;
  width: 100%;
  height: 100%;
}

.list {
  border-top: 1px $item-border-color solid;
}
