@import 'styles/variables.scss';
.location-select-box {
  position: relative;
  &.is-small {
    .location-list-inner {
      top: 32px;
    }
  }
}
.location-list-inner {
  position: absolute;
  top: 52px;
  z-index: 10;
}
.box-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
