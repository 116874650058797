@import 'styles/variables.scss';
.calendar-container {
  font-weight: bold;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.calendar {
  border-radius: 4px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  width: 100%;
}

.calendar-prev svg,
.calendar-next svg {
  cursor: pointer;
  font-size: 24px;
}

.calendar-year-month {
  font-weight: bold;
}

.calendar-body {
  margin-top: 10px;
}

.calendar-weekday {
  text-align: center;
  color: $col-gray6;
  padding: 0 0 10px;
}
.calendar-day {
  position: relative;
  width: 42px;
  height: 42px;
  margin: 0;
  padding: 0;
}
.calendar-day-item {
  font-size: 14px;
  text-align: center;
  color: $col-gray9;
  width: 100%;
  height: auto;
  line-height: 30px;
  cursor: pointer;

  &.is-selected {
    background: $col-primary;
    color: #fff;
    opacity: 1 !important;
    &.is-start-day {
      border-top-left-radius: 13px;
      border-bottom-left-radius: 13px;
    }
    &.is-end-day {
      border-top-right-radius: 13px;
      border-bottom-right-radius: 13px;
    }
    &.is-start-day-only {
      border-top-left-radius: 13px;
      border-bottom-left-radius: 13px;
      border-top-right-radius: 13px;
      border-bottom-right-radius: 13px;
    }
    &.is-daytrip {
      padding: 2px;
    }
    &.is-disabled-event {
      pointer-events: none;
    }
  }
  &.is-not-current-month {
    opacity: 0.4;
  }
  &.is-disabled {
    pointer-events: none;
    opacity: 0.1;
  }
}

.calendar-day-item-value {
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  display: block;
  height: auto;
  text-align: center;
  font-size: 20px;
  &.is-status-0 {
  }
  &.is-status-1 {
  }
  &.is-status-2 {
    font-size: 12px;
  }
  &.is-status-3 {
  }
  &.is-status-4 {
    font-size: 12px;
    bottom: -8px;
  }
  &.is-status-5 {
    font-size: 12px;
    bottom: -8px;
  }
  // 顧客優待カレンダーキャンセル待ち用
  &.is-status-999 {
    font-size: 12px;
    bottom: -8px;
  }
}

.calendar-sat {
  color: $col-secondary;
}
.calendar-sun {
  color: #f00;
}

.selectable-year-month {
  display: inline-block;
  cursor: pointer;
  text-decoration: underline;
}
