@import 'styles/variables.scss';
.selectable-year-month {
  width: 100%;
  height: 100%;
  background: #fff;
  position: absolute;
  top: 0;
  overflow-y: scroll;
}

.years,
.months {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.year-title,
.month-title {
  width: 100%;
  font-weight: bold;
  margin-top: 10px;
  text-align: center;
}

.year-pickup,
.month-pickup {
  background: $col-gray8;
  color: #fff;
}

.year,
.month {
  width: 30%;
  margin: 10px 4px;
  padding: 10px;
  text-align: center;
  border-radius: 3px;
  border: 1px $col-gray3 solid;
  cursor: pointer;
  &:hover {
    background: $col-gray1;
    color: $col-base;
  }
}
