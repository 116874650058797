@import 'styles/variables.scss';
.checkbox-text {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.text {
  padding-left: 3px;
  &.is-full {
    width: 100%;
  }
}
