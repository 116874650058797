@import 'styles/variables.scss';
.date-box {
  width: 100%;
  height: 48px;
  padding: 0 6px;
  background: #fff;
  &:active {
    opacity: 0.7;
  }
}
.select-box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .checkin-out {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .head {
    display: block;
    font-size: 1rem;
    color: $col-gray6;
  }
  .checkin,
  .checkout {
    width: 100%;
  }
  .staying {
    width: 50%;
  }
  .body,
  .staynum {
    display: block;
    color: $col-secondary;
    font-size: 1.4rem;
    font-weight: bold;
  }
}

.non-selected {
  line-height: $form-input-height;
  color: $col-base;
  display: block;
  .md-icon {
    width: 12px;
    min-width: 24px;
    height: 12px;
    font-size: 1.6rem !important;
    color: $col-gray6;
  }
}
