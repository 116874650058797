@import 'styles/variables.scss';
.location-box {
  width: 100%;
  height: 48px;
  padding: 0 6px;
  background: #fff;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  &:active {
    opacity: 0.7;
  }
  .md-icon {
    width: 12px;
    min-width: 24px;
    height: 12px;
    font-size: 1.6rem !important;
    color: $col-gray6;
  }
}

.icon {
  .md-icon {
    font-size: 1.4rem !important;
    width: 12px;
    min-width: 12px;
    height: 12px;
  }
}

.selected {
  line-height: 48px;
}

.not-selected {
  line-height: 50px;
  color: $col-gray6;
  padding: 0 12px;
}

.name-body {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.name-other {
  color: #68abdb;
  font-weight: 500;
}
