@import 'styles/variables.scss';
.date-box {
  width: 100%;
  height: 30px;
  padding: 0 15px;
  background: #fff;
  border-radius: 3px;
  cursor: pointer;
  &.is-disabled {
    pointer-events: none;
    color: $col-gray6;
  }
}

.checkin,
.checkout {
  line-height: 120%;
  padding-bottom: 3px;
}
.body {
  line-height: 30px;
}

.non-selected-text {
  line-height: 30px;
  color: $col-gray6;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
}
