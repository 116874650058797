@import 'styles/variables.scss';

.plan-item {
  display: block;
  position: relative;
  width: 100%;
  color: $col-base;
  padding: 12px;
  border-bottom: 1px solid $item-border-color;
  .plan-item-name {
    width: 100%;
    font-size: 1.4rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 4px;
  }
  .plan-item-tag {
    margin-bottom: 4px;
    .plan-item-tag-item {
      display: inline-block;
      width: auto;
      height: 20px;
      background: $col-gray1;
      /* border: 1px solid $item-border-color; */
      border-radius: 20px;
      margin: 0 4px 4px 0;
      padding: 0 4px;
      font-size: 1rem;
      font-weight: bold;
      line-height: 2rem;
      letter-spacing: 0;
      color: $col-gray7;
      &.is-creditcard {
        background: $col-accent;
      }
      // todo:↓↓↓2021年10月から11月末までの金土割・土日割キャンペーンが終了後削除↓↓↓
      &.is-fri-sun-discount {
        background: #9fdbdb;
      }
      &.is-sta-mon-discount {
        background: #d1b887;
      }
      // todo:↑↑↑2021年10月から11月末までの金土割・土日割キャンペーンが終了後削除↑↑↑
    }
  }
  .plan-item-price {
    font-size: 2rem;
    font-weight: bold;
    text-align: right;
    margin-bottom: 4px;
  }
  .plan-item-price-unit {
    font-size: 1.2rem;
  }
  .plan-item-campaign {
    color: $col-error;
    font-size: 1rem;
    font-weight: bold;
    text-align: right;
    margin-bottom: 0;
  }
  .tax {
    font-size: 1.2rem;
    font-weight: normal;
    color: $col-gray6;
  }
  .credit-icon {
    position: absolute;
    top: 0;
    left: 0;
  }
}
