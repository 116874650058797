@import 'styles/variables.scss';
.checkbox {
  position: relative;
  width: 14px;
  height: 14px;
  border: 1px $item-border-color solid;
  margin-right: 4px;
  background: #fff;
  .check {
    display: none;
  }
  &.is-checked {
    .check {
      display: flex;
      position: absolute;
      top: -2px;
      left: -2px;
      .md-icon {
        font-size: 1.4rem !important;
      }
    }
  }
}
