@import 'styles/variables.scss';
.date-select-box {
/*  position: relative;*/
  &.is-small {
    .calendar {
      top: 32px;
    }
  }
}

.input-area {
  position: relative;
}

.calendar {
  width: 400px;
  background: #fff;
  position: absolute;
  top: 4px;
  padding: 0;
  overflow: hidden;
  z-index: 5;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  .head {
    width: 100%;
    height: 48px;
    background: #fff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
    z-index: 10;
    .close {
      font-weight: normal;
    }
  }
  .control-list {
    display: flex;
  }
  .all-day {
    cursor: pointer;
    color: $col-error;
  }
  .close {
    cursor: pointer;
  }
  .shortcut-link-body {
    padding: 10px;
  }
}

.box-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.tabs {
  margin: 12px 12px 0 12px;
  button {
    font-size: 1.2rem;
    color: #bcbfc7;
    min-height: 36px;
  }
}

.tabs-root {
  min-height: 36px;
}

.tab-btn.Mui-selected {
  color: $col-white;
  background-color: #539fd6;
}

.calendar-box {
  position: absolute;
}
