@import 'styles/variables.scss';
.date-box {
  width: 100%;
  height: 48px;
  padding: 0 12px;
  background: #fff;
  border-radius: $border-radius;
  cursor: pointer;
  .non-selected {
    color: $col-gray6;
    .non-selected-text {
      line-height: 48px;
    }
  }
  .selected {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 1.75;
    color: $col-base;
    .checkin-out {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .head {
      font-size: 1rem;
      color: $col-gray6;
    }
    .separate {
      padding: 10px 0;
      margin-top: 10px;
    }
    .checkin,
    .checkout,
    .staying {
    }
    .body,
    .staynum {
      color: $col-secondary;
      font-size: 1.2rem;
      font-weight: bold;
      text-decoration: underline;
    }
    .staynum {
      // font-size: 1.3rem;
    }
  }
}
.non-selected-text {
  color: $col-base;
  display: flex;
  align-items: center;
  .md-icon {
    width: 12px;
    min-width: 24px;
    height: 12px;
    font-size: 1.6rem !important;
    color: $col-gray6;
  }
}
