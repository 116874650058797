@import 'styles/variables.scss';

.more-plan-link {
  color: $col-secondary;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: right;
  padding: 8px 12px;
  width: 100%;
  display: block;
  .md-icon {
    font-size: 1.6rem !important;
    width: 12px;
    height: 12px;
    font-weight: normal;
  }
}
.campsite-item {
  z-index: 1;
  position: relative;
  width: 100%;
  height: 160px;
  overflow: hidden;
  padding: 12px;
  .payment-wrap {
    z-index: 1;
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
  }
  .payment {
    display: block;
    width: 88px;
    height: 20px;
    background: #ffe1e1;
    border: 1px solid #ffa7a7;
    border-radius: 20px;
    font-size: 1rem;
    font-weight: bold;
    line-height: 20px;
    text-align: center;
    color: #dc3545;
    &.is-no {
      background: $col-gray1;
      border-color: $col-gray4;
      color: $col-base;
    }
    &.is-creditcard {
      width: 180px;
      margin-left: 10px;
      background: linear-gradient(
        135deg,
        #f8f241 0%,
        #fff7af 15%,
        #fffe00 74%,
        #f7ee7c 100%
      );
      border-color: $col-base;
      color: $col-base;
    }
  }
  .campsite-item-rank {
    z-index: 4;
    position: absolute;
    top: 12px;
    left: 12px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: baseline;
    line-height: 32px;
    font-weight: bold;
    background: #fff;
    color: $col-base;
    z-index: 2;
    &.is-one {
      background: #ffff00;
    }
    &.is-two {
      background: #ffff00;
    }
    &.is-three {
      background: #ffff00;
    }
    .rank-num {
      font-size: 1.6rem;
    }
    .rank-unit {
      font-size: 1rem;
    }
  }
  .campsite-item-content {
    width: 100%;
    height: auto;
    z-index: 1;
    position: absolute;
    padding: 12px;
    bottom: 0;
    left: 0;
  }
  .campsite-item-image-wrap {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000;
    z-index: 1;
    overflow: hidden;
    &::before {
      content: '';
      width: 100%;
      height: 100%;
      background: $col-bg-gradient;
      z-index: 2;
      position: absolute;
      top: 0;
      left: 0;
    }
    .campsite-item-image {
      width: 100%;
      height: 160px;
      object-fit: cover;
    }
  }
  .campsite-item-name {
    position: relative;
    z-index: 3;
    font-size: 1.6rem;
  }
  .campsite-item-location {
    position: relative;
    z-index: 3;
    font-size: 1.2rem;
  }
  .campsite-item-review {
    position: relative;
    z-index: 3;
    display: flex;
  }
  .campsite-item-comment {
    position: relative;
    z-index: 3;
    margin-left: 8px;
    font-size: 1.2rem;
  }
}
