@import 'styles/variables.scss';
.award-badge {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 9;
  width: 60px;
  height: 60px;
}
.right {
  right: 10px;
}
.left {
  left: 10px;
}
