@import 'styles/variables.scss';
.date-screen {
}

.calendar {
  z-index: 0;
  position: relative;
  margin: 12px 0 112px;
  .shortcut-link-body {
    margin-top: 20px;
  }
}

.selected-date {
  margin: 20px 12px 0;
  padding: 0 24px 0 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $item-border-color;
  border-radius: $border-radius;
  height: 48px;
  .check-in-date {
    width: 100%;
    font-weight: bold;
    text-align: center;
  }
  .check-out-date {
    width: 100%;
    font-weight: bold;
    text-align: center;
  }
  .separater {
    width: 25%;
    text-align: center;
  }
}

.tabs {
  margin: 12px;
  button {
    font-size: 1.2rem;
    color: #bcbfc7;
    min-height: 36px;
  }
}

.tabs-root {
  min-height: 36px;
}
