@import 'styles/variables.scss';

.accordion-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0 12px 1rem;
  border-bottom: 1px $item-border-color solid;
  background: #fff;
  .icon {
  }
  &.is-checked {
    font-weight: bold;
  }
  &.is-selected {
    color: $col-secondary;
  }
  &.is-selected-post-item {
    color: $col-secondary;
    font-weight: bold;
  }
}
