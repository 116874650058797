@import 'styles/variables.scss';
.location-list {
  transition: width 0.3s ease-out;
  width: 100%;
  background: #fff;
  padding: 0;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.75);
  .location-list-outer {
    padding: 12px;
    .suggest {
      width: 100%;
      .suggest-input {
        .input {
          .form-text {
            border: 0;
            border-bottom: 1px solid $item-border-color;
            border-radius: 0;
          }
        }
      }
    }
    .title {
      margin: 24px 0;
    }
    .location-list-inner {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .list {
        &:not(:first-child) {
        }
        .location-item-list {
          .location-item {
            position: relative;
            width: 320px;
            height: 40px;
            font-size: 1.2rem;
            font-weight: normal;
            line-height: 40px;
            border-bottom: 1px $item-border-color solid;
            text-overflow: ellipsis;
            cursor: pointer;
            .md-icon {
              color: $col-gray4;
            }
            .icon {
              position: absolute;
              top: 0;
              right: 0;
            }
            &.is-current,
            &:hover {
              color: $col-secondary;
              font-weight: bold;
            }
            &.is-checked {
              font-weight: bold;
            }
          }
        }
      }
    }
  }
  .head {
    width: 100%;
    height: 48px;
    background: #fff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
    z-index: 10;
    .close {
      font-weight: normal;
      cursor: pointer;
    }
    .control-list {
      display: flex;
    }
    .clear {
      cursor: pointer;
      color: $col-error;
    }
  }
  .search-button {
    border-radius: 0px;
  }
  .select-max-txt {
    margin: 5px 0;
    color: $col-error;
    font-weight: bold;
    padding: 0 10px;
  }
}
