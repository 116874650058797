@import 'styles/variables.scss';

.location-screen {
  padding-bottom: 50px;
}
.suggest-input-wrap {
  position: relative;
  margin: 24px 0 0;
  padding: 0 12px 24px;
  border-bottom: 1px solid $item-border-color;
  .clear-button {
    position: absolute;
    top: 14px;
    right: 20px;
    z-index: 1;
    .md-icon {
      font-size: 1rem !important;
      font-weight: bold;
      padding: 2px;
      width: 16px;
      min-width: 16px;
      height: 16px;
      background: $col-gray1;
      border-radius: 50%;
    }
  }
}
.search-input {
  margin-top: 45px;
}

.title {
  position: relative;
  overflow: hidden;
  margin: 0 !important;
  padding: 12px;
  background: $col-white;
  border-bottom: 1px solid $item-border-color;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1;
  letter-spacing: 0.5px;
  &::before {
    content: '';
    position: absolute;
    background: $col-primary;
    width: 4px;
    height: 40px;
    transform: translateY(-50%);
    top: 50%;
    left: 0;
  }
}
