@import 'styles/variables.scss';

.arrow-down {
  width: 100%;
  padding: 12px;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  color: $col-secondary;
  background: #fff;
  .md-icon {
    font-size: 1.6rem !important;
    min-width: 12px !important;
    width: 12px;
  }
}
