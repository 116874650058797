@import 'styles/variables.scss';
.location-box-static {
  width: 100%;
  height: 48px;
  padding: 0 12px;
  background: #fff;
  font-size: 1.4rem;
  line-height: 48px;
  border-radius: $border-radius;
  overflow: hidden;
  display: flex;
  align-items: center;
  cursor: pointer;
  &.selected {
    color: $col-base;
    .md-icon {
      width: 12px;
      min-width: 24px;
      height: 12px;
      font-size: 1.6rem !important;
      color: $col-gray6;
    }
  }
  &.not-selected {
    color: $col-gray6;
  }
}
.is-sm {
  .location-box-static {
    height: 30px;
    font-size: 1.2rem;
  }
  .selected {
    line-height: 30px;
  }
  .not-selected {
    line-height: 30px;
  }
}

.name-body {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.name-other {
  color: #68abdb;
  font-weight: 500;
}
