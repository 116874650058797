@import 'styles/variables.scss';
.location-list {
  &.is-lock {
    pointer-events: none;
  }
}

.location-link {
  color: $col-base;
  display: block;
  width: 100%;
  height: 100%;
}

.list-outer:first-child {
  border-top: 1px $item-border-color solid;
}

.list-inner {
  padding-left: 1rem;
}

.list {
  .list-body {
    overflow: hidden;
    height: auto;
  }
}

.search-button {
  padding: 12px 12px 0 12px;
}
